import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";
import React from "react";
import logo from "../images/logo.png";
import {
  Avatar,
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AppBarCollapse from "./AppBarCollapse";

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  header: {
    backgroundColor: "#ffffff",
    position: "fixed",
  },
  title: {
    color: "#7D2133",
    fontFamily: "Abril Fatface",
    fontWeight: "bold",
  },
  description: {
    color: "#7D2133",
    fontFamily: "Abril Fatface",
  },
  titleContainer: {
    margin: theme.spacing(1),
  },
  linkContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  toolbar: {
    width: "85%",
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Header = ({ siteTitle, siteDescription }) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.header} position="sticky">
      <div className={classes.linkContainer}>
        <Toolbar className={classes.toolbar}>
          <AnchorLink to="/#Home" className={classes.link}>
            <Avatar src={logo} className={classes.avatarLarge} />
            <div className={classes.titleContainer}>
              <Typography variant="h6" component="h1" className={classes.title}>
                {siteTitle}
              </Typography>
              <Typography
                variant="subtitle1"
                component="h1"
                className={classes.description}
              >
                {siteDescription}
              </Typography>
            </div>
          </AnchorLink>
          <AppBarCollapse />
        </Toolbar>
      </div>
    </AppBar>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const styles = theme => ({
  link: {
    textDecoration: "none",
    color: "inherit",
  },
});

const LinkWrapper = React.forwardRef((props, ref) => (
  <AnchorLink to={props.to} className={props.classes.link} underline="none">
    {props.children}
  </AnchorLink>
));

export default withStyles(styles)(LinkWrapper);

import React from "react";
import { Button, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import LinkWrapper from "./LinkWrapper";

const styles = theme => ({
  root: {
    position: "absolute",
    right: 0,
  },
  buttonBar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    margin: "10px",
    paddingLeft: "16px",
    right: 0,
    position: "relative",
    width: "100%",
    background: "transparent",
  },
  menuItem: {
    color: "#7D2133",
  },
  button: {
    color: "#7D2133",
    textTransform: "none",
  },
});

const AppBarCollapse = props => (
  <div className={props.classes.root}>
    <ButtonAppBarCollapse>
      <LinkWrapper to="/#Home">
        <MenuItem className={props.classes.menuItem}>Home</MenuItem>
      </LinkWrapper>
      <LinkWrapper to="/#OurService">
        <MenuItem className={props.classes.menuItem}>Our Services</MenuItem>
      </LinkWrapper>
      <LinkWrapper to="/#Workshop">
        <MenuItem className={props.classes.menuItem}>Workshops</MenuItem>
      </LinkWrapper>
      <LinkWrapper to="/#AboutUs">
        <MenuItem className={props.classes.menuItem}>About Us</MenuItem>
      </LinkWrapper>
      <LinkWrapper to="/#ContactUs">
        <MenuItem className={props.classes.menuItem}>Contact Us</MenuItem>
      </LinkWrapper>
    </ButtonAppBarCollapse>
    <div className={props.classes.buttonBar} id="appbar-collapse">
      <LinkWrapper to="/#Home">
        <Button size="medium" className={props.classes.button}>
          Home
        </Button>
      </LinkWrapper>
      <LinkWrapper to="/#OurService">
        <Button size="medium" className={props.classes.button}>
          Our Services
        </Button>
      </LinkWrapper>
      <LinkWrapper to="/#Workshop">
        <Button size="medium" className={props.classes.button}>
          Workshops
        </Button>
      </LinkWrapper>
      <LinkWrapper to="/#AboutUs">
        <Button size="medium" className={props.classes.button}>
          About Us
        </Button>
      </LinkWrapper>
      <LinkWrapper to="/#ContactUs">
        <Button size="medium" className={props.classes.button}>
          Contact Us
        </Button>
      </LinkWrapper>
    </div>
  </div>
);

export default withStyles(styles)(AppBarCollapse);
